import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import encryptor from '~/plugins/encryption.js'

export const state = () => ({
  loading: false,
  error: {},
  data: [],
  matchIds: [],
  isEmpty: false,
  isFavAdded: false
})

export const mutations = {
  set_matchIds(state, data) {
    this.commit('socketFavorite/SET_IDS', data)
    if (this.$router.currentRoute.name === 'favorites') {
      state.matchIds = data
      if (data.length > 0) {
        this.commit('socketRate/SET_IDS', data)
        this.commit('socketBookmaker/SET_IDS', data)
        // this.commit('socketMain/SET_IDS', data)
      }
      this.commit('placeBet/set_matchIds', data)
    }
  },
  set_data(state, data) {
    state.data = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_isFavAdded(state, data) {
    state.isFavAdded = data
  }
}

export const actions = {
  async toggle({ commit }, { marketId, status, game }) {
    function toTitleCase(str) {
      return str
        .toLowerCase() // Convert the whole string to lowercase first
        .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
    }

    try {
      await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_V2_API_URL,
        url: 'FavouriteMatch/saveFavourites',
        data: {
          _accessToken: this.$auth.getToken('customLocal'),
          marketId,
          status
        }
      })
      if (this.$auth.loggedIn) {
        sessionStorage.removeItem('set_Favorites')
        sessionStorage.removeItem('set_Favorites_timezone')
        sessionStorage.removeItem('set_Favorites_MatchIds')
        sessionStorage.removeItem('set_Favorites_IsEmpty')
        this.dispatch('favorites/get')
        // Removing Markets Data to clear cache.
        sessionStorage.removeItem('set_MarketsById_' + toTitleCase(game))
        sessionStorage.removeItem(
          'set_MarketsById_timezone_' + toTitleCase(game)
        )
        sessionStorage.removeItem(
          'set_MarketsById_MatchIds_' + toTitleCase(game)
        )
        sessionStorage.removeItem(
          'set_MarketsById_IsEmpty_' + toTitleCase(game)
        )
        if (status) {
          commit('set_isFavAdded', status)
        }
      }
    } catch (error) {
      commit('set_error', error)
    }
  },
  async get({ commit }) {
    // This will stored data for 10mins.
    if (
      sessionStorage.getItem('set_Favorites_timezone') &&
      sessionStorage.getItem('set_Favorites')
    ) {
      const aa = sessionStorage.getItem('set_Favorites_timezone')
      const now = new Date().getTime()
      const distance = now - aa
      const seconds = Math.floor(distance / 1000)
      const minutes = Math.floor(seconds / 60)
      if (minutes >= 10) {
        sessionStorage.removeItem('set_Favorites')
        sessionStorage.removeItem('set_Favorites_timezone')
        sessionStorage.removeItem('set_Favorites_MatchIds')
        sessionStorage.removeItem('set_Favorites_IsEmpty')
      }
    }
    if (process.client && sessionStorage.getItem('set_Favorites')) {
      const data = await JSON.parse(
        encryptor.decrypt(sessionStorage.getItem('set_Favorites'))
      )
      const matchIdsData = await JSON.parse(
        encryptor.decrypt(sessionStorage.getItem('set_Favorites_MatchIds'))
      )
      const isEmptyData = await JSON.parse(
        encryptor.decrypt(sessionStorage.getItem('set_Favorites_IsEmpty'))
      )
      commit('set_data', data)
      commit('set_matchIds', matchIdsData)
      commit('set_isEmpty', isEmptyData)
      return
    }

    try {
      let user
      if (this.$auth.loggedIn) {
        user = this.$auth.user._id
      }
      commit('set_data', [])
      sessionStorage.setItem(
        'set_Favorites',
        encryptor.encrypt(JSON.stringify([]))
      )
      commit('set_loading', true)
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_V2_API_URL,
        url: 'FavouriteMatch/getFavouritesMarket',
        data: {
          user,
          _accessToken: this.$auth.getToken('customLocal')
        }
      })
      if (data.data && data.data.length > 0) {
        commit('set_isEmpty', false)
        sessionStorage.setItem(
          'set_Favorites_IsEmpty',
          encryptor.encrypt(JSON.stringify(false))
        )
      } else {
        commit('set_isEmpty', true)
        sessionStorage.setItem(
          'set_Favorites_IsEmpty',
          encryptor.encrypt(JSON.stringify(true))
        )
      }
      if (data.data) {
        const matchIds = map(data.data, (match) => match.betfairId)
        commit('set_matchIds', matchIds)
        sessionStorage.setItem(
          'set_Favorites_MatchIds',
          encryptor.encrypt(JSON.stringify(matchIds))
        )
        const grouped = groupBy(data.data, 'eventType.name')
        const mapped = map(grouped, (val, key) => {
          return { tab: key, fixtures: val.filter((v, i) => i <= 1) } // limiting to show only 2
        })
        commit('set_data', mapped)
        sessionStorage.setItem(
          'set_Favorites',
          encryptor.encrypt(JSON.stringify(mapped))
        )
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async toggleShow({ commit }, status) {
    await commit('set_isFavAdded', status)
  }
}
