import encryptor from '~/plugins/encryption.js'

const DB_NAME = 'SportsbookDB'
const STORE_NAME = 'MarketsStore'

// Initialize IndexedDB
function initDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1)

    request.onupgradeneeded = (event) => {
      const db = event.target.result
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME)
      }
    }

    request.onsuccess = () => {
      resolve(request.result)
    }

    request.onerror = () => {
      reject(request.error)
    }
  })
}

// Get data from IndexedDB
function getFromDB(db, key) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly')
    const store = transaction.objectStore(STORE_NAME)
    const request = store.get(key)

    request.onsuccess = () => {
      resolve(request.result)
    }

    request.onerror = () => {
      reject(request.error)
    }
  })
}

// Set data to IndexedDB
function setToDB(db, key, value) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite')
    const store = transaction.objectStore(STORE_NAME)
    const request = store.put(value, key)

    request.onsuccess = () => {
      resolve(true)
    }

    request.onerror = () => {
      reject(request.error)
    }
  })
}

export const state = () => ({
  data: [],
  isEmpty: false,
  matchIds: [],
  loading: false,
  error: ''
})

export const mutations = {
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_matchIds(state, data) {
    state.matchIds = data
    this.commit('placeBet/set_matchIds', state.matchIds)
  },
  set_data(state, { matchData = [] }) {
    const matches = []
    if (matchData) {
      matches.push({
        tab: 'Live & Upcoming',
        icon: 'mdi-motion-play-outline',
        fixtures: matchData
      })
    }
    state.data = matches
  },
  set_error(state, data) {
    state.error = data
    this.commit('snackbar/open', {
      text: state.error,
      color: 'error'
    })
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getMarketsById(
    { commit },
    {
      game,
      competitionId,
      loading = true,
      socketData,
      upComing = false,
      useCache = false
    }
  ) {
    commit('set_loading', loading)
    const db = await initDB()
    const compId = competitionId ? '_' + competitionId : ''
    const cacheKey = `set_MarketsById_${game}${compId}`
    const timestampKey = `set_MarketsById_timezone_${game}${compId}`
    const matchIdsKey = `set_MarketsById_MatchIds_${game}${compId}`
    const isEmptyKey = `set_MarketsById_IsEmpty_${game}${compId}`

    if (useCache) {
      console.log('Use Cache')
      const timestamp = await getFromDB(db, timestampKey)
      const now = new Date().getTime()
      const distance = now - timestamp
      const minutes = Math.floor(distance / 60000)

      if (minutes >= 10) {
        console.log('Time Expired fetching....')
        await setToDB(db, cacheKey, null)
        await setToDB(db, timestampKey, null)
        await setToDB(db, matchIdsKey, null)
        await setToDB(db, isEmptyKey, null)
      }

      const cachedData = await getFromDB(db, cacheKey)
      const matchIdsData = await getFromDB(db, matchIdsKey)
      const isEmptyData = await getFromDB(db, isEmptyKey)

      if (cachedData) {
        const data = JSON.parse(encryptor.decrypt(cachedData))
        const matchIds = JSON.parse(encryptor.decrypt(matchIdsData))
        const isEmpty = JSON.parse(encryptor.decrypt(isEmptyData))

        if (matchIds.length > 0) {
          this.commit('socketRate/SET_IDS', matchIds)
          this.commit('socketBookmaker/SET_IDS', matchIds)
        }

        commit('set_matchIds', matchIds)
        commit('set_isEmpty', isEmpty)
        commit('set_data', data)
        commit('set_loading', false)
        return
      }
    }

    commit('set_matchIds', [])
    await setToDB(db, matchIdsKey, encryptor.encrypt(JSON.stringify([])))

    let userId
    try {
      if (loading) {
        commit('set_data', [])
        await setToDB(db, cacheKey, encryptor.encrypt(JSON.stringify([])))
      }

      let matchData
      if (socketData) {
        matchData = socketData
      } else {
        const { data: responseData } = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_API_URL,
          url: 'category/getMarketIds',
          data: {
            game,
            competitionId,
            _id: userId,
            _accessToken: this.$auth.getToken('customLocal'),
            accessLevel: 'Player',
            isFancy: false,
            upComing
          }
        })
        matchData = responseData ? responseData.data : undefined

        if (responseData && responseData.data && !responseData.data.length) {
          console.log('Empty here')
          commit('set_isEmpty', true)
          commit('set_data', [])
          await setToDB(db, isEmptyKey, encryptor.encrypt(JSON.stringify(true)))
          await setToDB(db, cacheKey, encryptor.encrypt(JSON.stringify([])))
        }
      }

      if (matchData && matchData.length > 0 && Array.isArray(matchData)) {
        const matchIds = []
        matchData.forEach((market) => {
          if (market?.betfairId) {
            matchIds.push(market.betfairId)
          }
        })

        if (matchIds.length > 0) {
          this.commit('socketRate/SET_IDS', matchIds)
          this.commit('socketBookmaker/SET_IDS', matchIds)
        }

        commit('set_matchIds', matchIds)
        await setToDB(
          db,
          matchIdsKey,
          encryptor.encrypt(JSON.stringify(matchIds))
        )
        commit('set_isEmpty', false)
        await setToDB(db, isEmptyKey, encryptor.encrypt(JSON.stringify(false)))

        commit('set_data', { matchData })
        await setToDB(
          db,
          cacheKey,
          encryptor.encrypt(JSON.stringify({ matchData }))
        )
      }

      await setToDB(db, timestampKey, new Date().getTime())
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error.message || 'Error fetching data')
      commit('set_loading', false)
    }
  }
}
