<template>
  <div>
    <v-dialog v-model="cacheDialog" persistent max-width="400">
      <v-card light>
        <v-card-title>
          <div class="title">New version available!</div>
        </v-card-title>
        <v-card-text class="pb-0"
          >Please update to the new version to experience latest
          features.</v-card-text
        >
        <v-card-actions class="px-6 py-4 text-left">
          <v-btn
            class=""
            color="primary"
            :loading="loading"
            @click="clearCacheInstance()"
            >Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="region"
      persistent
      transition="dialog-top-transition"
      max-width="550"
    >
      <v-card>
        <v-toolbar color="primary" class="text-h6 text-center"
          >🚫 Not Allowed
        </v-toolbar>
        <marquee width="100%" behavior="alternate">
          <v-card-text>
            <div class="text-h6 pa-4">
              {{ title }} is unavailable in your Region
            </div>
          </v-card-text>
        </marquee>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import constants from '../const'
export default {
  data() {
    return {
      title: process.env.THEME_GENERAL_TITLE || 'This Website',
      region: false,
      cacheDialog: false,
      loading: false,
      getVersion: '',
      myNewVersion: '1.2.1.1'
    }
  },
  computed: {
    ...mapState({ error: (state) => state.user.error }),
    getsetversion() {
      return this.$store.state.user.appversion
    }
  },
  watch: {
    getsetversion() {
      if (this.getsetversion) {
        this.checkVersionForWeb()
      }
    }
  },
  methods: {
    checkVersionForWeb() {
      this.getVersion = this.getsetversion.appVersion
      const oldVersion = localStorage.getItem(
        'UIVERSION' + window.location.host
      )
      console.log('API & UI_', this.getVersion, oldVersion)
      if (this.getVersion && this.getVersion !== '9999.9999.9999.9999') {
        if (oldVersion === '' || oldVersion == null) {
          // this.saveVersion(this.getVersion)
          // this.cacheDialog = true
          localStorage.setItem(
            'UIVERSION' + window.location.host,
            this.myNewVersion
          )
        } else if (oldVersion && oldVersion === this.getVersion) {
          this.cacheDialog = false
        } else {
          this.saveVersion(this.getVersion)
          this.cacheDialog = true
        }
      }
      // else if (this.getVersion === undefined && this.error) {
      //   this.$router.push('/region-block')
      // }
    },
    closeCacheModal() {
      this.cacheDialog = false
    },
    saveVersion(getVersion) {
      this.getVersion = getVersion
    },
    clearCacheInstance() {
      this.loading = true
      localStorage.setItem(
        'UIVERSION' + window.location.host,
        this.myNewVersion
      )
      if ('caches' in window) {
        caches.keys().then(function (cacheNames) {
          return cacheNames.map(function (cache) {
            return caches.delete(cache).then(function (found) {
              console.log('Delete Cache:-', found)
            })
          })
        })
      }

      this.loading = false
      this.cacheDialog = false
      if (process.client) {
        window.location.reload()
      }
    }
  }
}
</script>

<style></style>
